// TODO: change here
// export const BASE_API_URL = `https://localhost:7053/api`;
export const BASE_API_URL = `https://api.theskyviewreality.com/api`;
export const APPLICATION = `Web`;
export const APPLICATION_TYPE = `EmployeeManagementSystem`;
// dev | design | QA | production
export const ENVIRONMENT = `production`;
export const AppTokenKey = "AppTokenSkyView";
export const UserDetails = "UserDetails";
export const LocalAppEncryptKey = "theskyview321";
